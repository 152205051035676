import React from "react"

import Nav from "../components/Nav"
import logo from '../assets/images/logo.svg'

const Header = (props) => (
  <header>
    <div className="container sp-full flex-container align-justify">
      <h1 className="logo">
        <a href="/">
          <img src={logo} alt={props.siteTitle} />
        </a>
      </h1>
      <Nav />
    </div>
  </header>
)

export default Header
