import React from "react"

// import logo from '../assets/images/logo.svg'
import iconInstagram from '../assets/images/icon-instagram.svg'
import iconTwitter from '../assets/images/icon-twitter.svg'



class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = { menu: 'hide' }
		this.handleClick = this.handleClick.bind(this)
	}
	handleClick() {
		this.setState(state => ({
			menu: !state.menu 
			}))
		}

	render() {
		return (
			<nav className="nav-global">

				<button 
					className={`nav-button ${ this.state.menu ? 'hide' : 'nav-active'}`}
					type="button"
					onClick={this.handleClick}	
				>
					<span className="ico_humbarger"></span>
				</button>

				<div className={`nav-menu ${ this.state.menu ? 'hide' : 'nav-active'}`}>
					<div className="wrap-center">
						<div className="container">
							{/* <a 
								href="#"
								className="nav-menu__logo"
								onClick={this.handleClick}
							>
								<img src={logo} alt={this.props.siteTitle}/>
							</a> */}
							<ul className="nav-menu__lists no-bullet">
								<li className="nav-menu__list">
									<a 
										href="#concept" 
										className="nav-menu__link"
										onClick={this.handleClick}
									>
										CONCEPT
									</a>
								</li>
								<li className="nav-menu__list">
									<a 
										href="#flavor" 
										className="nav-menu__link"
										onClick={this.handleClick}
									>
										FLAVOR
									</a>
								</li>
								<li className="nav-menu__list">
									<a 
										href="#stores" 
										className="nav-menu__link"
										onClick={this.handleClick}
									>
										STORES
									</a>
								</li>
								<li className="nav-menu__list nav-menu__list--social">
									<a 
										href="https://www.instagram.com/tempura.tokyo/" 
										target="_blank" 
										rel="noopener noreferrer" 
										className="nav-menu__link nav-menu__link--instagram"
									>
									<img src={iconInstagram} alt="天麩羅TOKYO Instagram" />
									</a>
									<a 
										href="https://twitter.com/TOKYO04455693" 
										target="_blank" 
										rel="noopener noreferrer" 
										className="nav-menu__link nav-menu__link--twitter"
									>
									<img src={iconTwitter} alt="天麩羅TOKYO Twitter" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
			</nav>
		)
	}
}

// const Nav = (props) => {
// 	return (
// 		<nav className="nav-global">

// 			<div className="flex-container align-right">
// 				<div className="nav-button js-expand-button">
// 					<span className="ico_humbarger"></span>
// 					<span className="ico_humbarger"></span>
// 				</div>
// 			</div>

// 			<div className="nav-menu js-expand">
// 				<div className="wrap-center">
// 					<div className="container">
// 						<a href="/" className="nav-menu__logo">
// 							<img src={logo} alt={props.siteTitle}/>
// 						</a>
// 						<ul className="nav-menu__lists no-bullet">
// 							<li className="nav-menu__list">
// 							</li>
// 							<li className="nav-menu__list">
// 								<a href="#concept" className="nav-menu__link">CONCEPT</a>
// 							</li>
// 							<li className="nav-menu__list">
// 								<a href="#flavor" className="nav-menu__link">FLAVOR</a>
// 							</li>
// 							<li className="nav-menu__list">
// 								<a href="#stores" className="nav-menu__link">STORES</a>
// 							</li>
// 							<li className="nav-menu__list nav-menu__list--social">
// 								<a 
// 									href="https://www.instagram.com/tempura.tokyo/" 
// 									target="_blank" 
// 									rel="noopener noreferrer" 
// 									className="nav-menu__link nav-menu__link--instagram"
// 								>
// 								<img src={iconInstagram} alt="天麩羅TOKYO Instagram" />
// 								</a>
// 								<a 
// 									href="https://twitter.com/TOKYO04455693" 
// 									target="_blank" 
// 									rel="noopener noreferrer" 
// 									className="nav-menu__link nav-menu__link--twitter"
// 								>
// 								<img src={iconTwitter} alt="天麩羅TOKYO Twitter" />
// 								</a>
// 							</li>
// 						</ul>
// 					</div>
// 				</div>
// 			</div>
			
// 		</nav>
// 	)
// }

export default Nav
