import React from "react"
import Fade from 'react-reveal/Fade';

import logo from '../assets/images/logo.svg'
import logoHotarunoHikari from '../assets/images/logo-hotarunohikari.svg'

const Footer = (props) => (
  <footer>
		<Fade delay={200}>
			<div className="container text-center">
				<div className="services">
					<a 
						href="/"
						title={props.siteTitle}
					>
						<img src={logo} alt={props.siteTitle} />
					</a>
					<a 
						href="http://hotarunohikari.jp/"
						target="_blank" 
						rel="noopener noreferrer"
						title="Hotaru no Hikari"
					>
						<img src={logoHotarunoHikari} alt="Hotaru no Hikari" />
					</a>
				</div>
				<span>© {new Date().getFullYear()}, Tempura Tokyo</span>
			</div>
		</Fade>
  </footer>
)

export default Footer

